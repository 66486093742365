var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "socialSecurityScheme" },
    [
      _c(
        "div",
        { staticClass: "socialSecurityScheme-head" },
        [
          _c(
            "a-button",
            { attrs: { prefix: "add" }, on: { click: _vm.addRemind } },
            [_vm._v(" 新增消息提醒 ")]
          ),
        ],
        1
      ),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          pagination: false,
          "row-key": "remindId",
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "earlyDays",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("div", [
                  _vm._v(
                    "增减员截至日期前" + _vm._s(scope.record.earlyDays) + "天"
                  ),
                ]),
              ]
            },
          },
          {
            key: "iodsEndDay",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("div", [
                  _vm._v("每月" + _vm._s(scope.record.iodsEndDay) + "日"),
                ]),
              ]
            },
          },
          {
            key: "state",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("a-switch", {
                  on: {
                    change: function ($event) {
                      return _vm.changeRemindStatusApi(scope.record)
                    },
                  },
                  model: {
                    value: scope.record.status,
                    callback: function ($$v) {
                      _vm.$set(scope.record, "status", $$v)
                    },
                    expression: "scope.record.status",
                  },
                }),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "span",
                  { staticClass: "operation-btn-box" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(scope.record)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticClass: "delete",
                        attrs: { type: "link", danger: "" },
                        on: {
                          click: function ($event) {
                            return _vm.delRemind(scope.record)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "Drawer",
        {
          attrs: {
            title: _vm.title === "add" ? "新增消息提醒" : "编辑消息提醒",
            closable: false,
            size: "small",
            visible: _vm.visible,
          },
          on: { close: _vm.onClose, onSubmit: _vm.submit },
        },
        [
          _c(
            "div",
            { staticClass: "deawer-content" },
            [
              _c(
                "a-form-model",
                {
                  ref: "messageForm",
                  attrs: {
                    model: _vm.messageForm,
                    rules: _vm.rules,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "消息类别", prop: "messageType" } },
                    [
                      _c("dictionariesInput", {
                        attrs: { disabled: "", parameter: "remind_type" },
                        model: {
                          value: _vm.messageForm.messageType,
                          callback: function ($$v) {
                            _vm.$set(_vm.messageForm, "messageType", $$v)
                          },
                          expression: "messageForm.messageType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "提醒事件", prop: "reminderEvents" } },
                    [
                      _c("dictionariesInput", {
                        attrs: {
                          placeholder: "请选择提醒事件",
                          parameter: "reminder",
                        },
                        model: {
                          value: _vm.messageForm.reminderEvents,
                          callback: function ($$v) {
                            _vm.$set(_vm.messageForm, "reminderEvents", $$v)
                          },
                          expression: "messageForm.reminderEvents",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "所属城市", prop: "provinceCity" } },
                    [
                      _c("CbSelectArea", {
                        ref: "selectArea",
                        attrs: {
                          "area-show": false,
                          "province-width": 150,
                          "government-auto": true,
                          "city-width": 150,
                        },
                        on: { change: _vm.onChange },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "增减员截止日期", prop: "messageDate" } },
                    [
                      _c(
                        "div",
                        { staticClass: "number" },
                        [
                          _vm._v(" 每月 "),
                          _c("CbNumber", {
                            attrs: { max: 28 },
                            model: {
                              value: _vm.messageForm.messageDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.messageForm, "messageDate", $$v)
                              },
                              expression: "messageForm.messageDate",
                            },
                          }),
                          _vm._v("日 "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "提醒时间", prop: "messageTime" } },
                    [
                      _c("span", [_vm._v("增减员截止日期前")]),
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100px", margin: "0 16px" },
                          model: {
                            value: _vm.messageForm.messageTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.messageForm, "messageTime", $$v)
                            },
                            expression: "messageForm.messageTime",
                          },
                        },
                        _vm._l(10, function (index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: index } },
                            [_vm._v(_vm._s(index))]
                          )
                        }),
                        1
                      ),
                      _c("span", [_vm._v("天")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }