var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "socialSecurityScheme" },
    [
      _c(
        "div",
        { staticClass: "socialSecurityScheme-head" },
        [
          _c(
            "a-button",
            { attrs: { prefix: "add" }, on: { click: _vm.addSubject } },
            [_vm._v(" 新增参保主体 ")]
          ),
        ],
        1
      ),
      _c("custom-table", {
        attrs: {
          scroll: { y: 650 },
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          pagination: false,
          "row-key": "companyId",
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "defaultValue",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("a-switch", {
                  attrs: {
                    checked: scope.record.defaultValue === "1" ? true : false,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setDefault(scope.record)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "span",
                  { staticClass: "operation-btn-box" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(scope.record)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticClass: "delete",
                        attrs: { type: "link", danger: "" },
                        on: {
                          click: function ($event) {
                            return _vm.delCompany(scope.record)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "Drawer",
        {
          attrs: {
            title: _vm.title === "add" ? "新增参保主体" : "编辑参保主体",
            closable: false,
            size: "small",
            visible: _vm.visible,
          },
          on: { close: _vm.onClose, onSubmit: _vm.submit },
        },
        [
          _c(
            "div",
            { staticClass: "deawer-content" },
            [
              _c(
                "a-form-model",
                {
                  ref: "messageForm",
                  attrs: {
                    model: _vm.messageForm,
                    rules: _vm.rules,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "公司名称", prop: "companyName" } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "请填写公司名称",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.messageForm.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.messageForm, "companyName", $$v)
                          },
                          expression: "messageForm.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: "统一社会信用代码", prop: "uniformCode" },
                    },
                    [
                      _c("a-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "请填写18位统一社会信用代码",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.messageForm.uniformCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.messageForm, "uniformCode", $$v)
                          },
                          expression: "messageForm.uniformCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "所属城市", prop: "provinceCity" } },
                    [
                      _c("CbSelectArea", {
                        ref: "selectArea",
                        attrs: {
                          "government-auto": true,
                          "area-show": false,
                          "province-width": 150,
                          "city-width": 150,
                        },
                        on: { change: _vm.onChange },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "社保官方平台登录地址",
                        prop: "socialUrl",
                      },
                    },
                    [
                      _c("a-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "链接地址包括前缀https或http",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.messageForm.socialUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.messageForm, "socialUrl", $$v)
                          },
                          expression: "messageForm.socialUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.goUrl(_vm.messageForm.socialUrl)
                            },
                          },
                        },
                        [_vm._v("测试登录情况")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "医保官方平台登录地址",
                        prop: "medicalUrl",
                      },
                    },
                    [
                      _c("a-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "链接地址包括前缀https或http",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.messageForm.medicalUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.messageForm, "medicalUrl", $$v)
                          },
                          expression: "messageForm.medicalUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.goUrl(_vm.messageForm.medicalUrl)
                            },
                          },
                        },
                        [_vm._v("测试登录情况")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "公积金官方平台登录地址",
                        prop: "housingUrl",
                      },
                    },
                    [
                      _c("a-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "链接地址包括前缀https或http",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.messageForm.housingUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.messageForm, "housingUrl", $$v)
                          },
                          expression: "messageForm.housingUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.goUrl(_vm.messageForm.housingUrl)
                            },
                          },
                        },
                        [_vm._v("测试登录情况")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }