var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "socialSecurityScheme" },
        [
          _c(
            "div",
            { staticClass: "socialSecurityScheme-head" },
            [
              _c(
                "a-button",
                { attrs: { prefix: "add" }, on: { click: _vm.addScheme } },
                [_vm._v(" 新增医保方案 ")]
              ),
            ],
            1
          ),
          _c("custom-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.dataSource,
              pagination: false,
              "row-key": "schemeId",
            },
            on: { change: _vm.loadDataList },
            scopedSlots: _vm._u(
              [
                {
                  key: "operation",
                  fn: function (ref) {
                    var scope = ref.scope
                    return [
                      _c(
                        "span",
                        { staticClass: "operation-btn-box" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 调整 ")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticClass: "delete",
                              attrs: { type: "link", danger: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteConfirm(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              24031620
            ),
          }),
        ],
        1
      )
    : _c("addScheme", {
        attrs: {
          title: _vm.title === "add" ? "新增医保方案" : "编辑医保方案",
          "scheme-id": _vm.schemeId,
          "insure-classify": "medical",
          "operate-type": _vm.title,
        },
        on: { saveForm: _vm.saveForm, back: _vm.back },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }