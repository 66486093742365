var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addScheme" },
    [
      _c("HeaderBack", {
        attrs: { title: _vm.title },
        on: { back: _vm.toggleValue },
      }),
      _c(
        "div",
        { staticClass: "addScheme-content" },
        [
          _c(
            "a-form-model",
            {
              ref: "addForm",
              attrs: {
                model: _vm.form,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "参保城市", prop: "provinceCity" } },
                [
                  _c("CbSelectArea", {
                    ref: "selectArea",
                    attrs: {
                      "province-disabled": _vm.operateType === "edit",
                      "city-disabled": _vm.operateType === "edit",
                      "area-show": false,
                      "government-auto": true,
                      "province-width": 200,
                      "city-width": 200,
                    },
                    on: { change: _vm.onChangeArea },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "方案名称", prop: "schemeName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入方案名称" },
                    model: {
                      value: _vm.form.schemeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "schemeName", $$v)
                      },
                      expression: "form.schemeName",
                    },
                  }),
                ],
                1
              ),
              _vm.showTemplate
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "方案参考模板" } },
                    [
                      _vm.templates.length > 0
                        ? _c(
                            "div",
                            _vm._l(_vm.templates, function (item, index) {
                              return _c(
                                "a-popover",
                                {
                                  key: index,
                                  staticClass: "reference_template",
                                  attrs: { title: "参考方案模板" },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "content" },
                                    [
                                      _c("custom-table", {
                                        attrs: {
                                          columns: _vm.popoverColumns,
                                          "data-source":
                                            item.builtinSchemeDataList,
                                          pagination: false,
                                          scroll: { x: true },
                                          "row-key": "insureCode",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "companyScale",
                                              fn: function (ref) {
                                                var scope = ref.scope
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        Number(
                                                          scope.record
                                                            .companyScale
                                                        ).toFixed(2)
                                                      ) +
                                                      "% "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "personScale",
                                              fn: function (ref) {
                                                var scope = ref.scope
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        Number(
                                                          scope.record
                                                            .personScale
                                                        ).toFixed(2)
                                                      ) +
                                                      "% "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      class:
                                        _vm.colorChange === item.value
                                          ? "button-color"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectScheme(item.value)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  ),
                                ],
                                2
                              )
                            }),
                            1
                          )
                        : _c("a-button", { attrs: { disabled: "" } }, [
                            _vm._v("暂无参考模板"),
                          ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "设定为默认方案" } },
                [
                  _c(
                    "a-checkbox",
                    {
                      attrs: {
                        checked: _vm.form.defaultValue === "1" ? true : false,
                      },
                      on: { change: _vm.upDefaultVal },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.checkedDefault
                              ? "该城市下已有默认方案"
                              : _vm.form.defaultValue === "0"
                              ? "该城市下暂无默认方案"
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "insurance-list" },
            [
              _c(
                "custom-table",
                {
                  attrs: {
                    columns: _vm.columns,
                    "data-source": _vm.dataSource,
                    pagination: false,
                    scroll: { x: true },
                    "row-key": "insureCode",
                  },
                  on: { change: _vm.loadDataList },
                  scopedSlots: _vm._u([
                    {
                      key: "baseLow",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("NumThousand", {
                            attrs: {
                              "v-model": scope.record.baseLow,
                              "decimal-separator": true,
                              "int-val": scope.record.baseLow,
                              "is-thousands": true,
                              precision: "2",
                              numamount: "true",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.inputChangeFun(
                                  arguments[0],
                                  scope,
                                  "baseLow"
                                )
                              },
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "baseTop",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("NumThousand", {
                            attrs: {
                              "v-model": scope.record.baseTop,
                              "int-val": scope.record.baseTop,
                              "decimal-separator": true,
                              "is-thousands": true,
                              precision: "2",
                              numamount: "true",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.inputChangeFun(
                                  arguments[0],
                                  scope,
                                  "baseTop"
                                )
                              },
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "companyScale",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("CbNumber", {
                            attrs: {
                              max: 100,
                              "decimal-separator": true,
                              precision: "2",
                              suffix: "%",
                            },
                            model: {
                              value: scope.record.companyScale,
                              callback: function ($$v) {
                                _vm.$set(scope.record, "companyScale", $$v)
                              },
                              expression: "scope.record.companyScale",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "companyExtra",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("NumThousand", {
                            attrs: {
                              "v-model": scope.record.companyExtra,
                              "int-val": scope.record.companyExtra,
                              "is-thousands": true,
                              "decimal-separator": "",
                              precision: "2",
                              numamount: "true",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.inputChangeFun(
                                  arguments[0],
                                  scope,
                                  "companyExtra"
                                )
                              },
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "companyTrunc",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "get-popup-container": _vm.getPopupContainer,
                              },
                              model: {
                                value: scope.record.companyTrunc,
                                callback: function ($$v) {
                                  _vm.$set(scope.record, "companyTrunc", $$v)
                                },
                                expression: "scope.record.companyTrunc",
                              },
                            },
                            _vm._l(_vm.roundingRules, function (item, index) {
                              return _c(
                                "a-select-option",
                                {
                                  key: index,
                                  attrs: {
                                    value: item.value,
                                    title: item.label,
                                  },
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "personScale",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("CbNumber", {
                            attrs: {
                              "decimal-separator": true,
                              precision: "2",
                              max: 100,
                              suffix: "%",
                            },
                            model: {
                              value: scope.record.personScale,
                              callback: function ($$v) {
                                _vm.$set(scope.record, "personScale", $$v)
                              },
                              expression: "scope.record.personScale",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "personExtra",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("NumThousand", {
                            attrs: {
                              "v-model": scope.record.personExtra,
                              "int-val": scope.record.personExtra,
                              "is-thousands": true,
                              "decimal-separator": true,
                              precision: "2",
                              numamount: "true",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.inputChangeFun(
                                  arguments[0],
                                  scope,
                                  "personExtra"
                                )
                              },
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "personTrunc",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "get-popup-container": _vm.getPopupContainer,
                              },
                              model: {
                                value: scope.record.personTrunc,
                                callback: function ($$v) {
                                  _vm.$set(scope.record, "personTrunc", $$v)
                                },
                                expression: "scope.record.personTrunc",
                              },
                            },
                            _vm._l(_vm.roundingRules, function (item, index) {
                              return _c(
                                "a-select-option",
                                {
                                  key: index,
                                  attrs: {
                                    value: item.value,
                                    title: item.label,
                                  },
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "operation",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c(
                            "a-button",
                            {
                              staticClass: "delete",
                              attrs: {
                                disabled: _vm.dataSource.length <= 1,
                                type: "link",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleted(scope)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "footer" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "link", prefix: "add-one" },
                          on: { click: _vm.addInsuranceType },
                        },
                        [_c("span", [_vm._v("添加险种")])]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "a-button",
                {
                  staticClass: "addScheme-buttom",
                  attrs: { type: "primary" },
                  on: { click: _vm.storage },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
          _c(
            "Drawer",
            {
              attrs: {
                title: "添加险种",
                closable: false,
                size: "small",
                visible: _vm.visible,
              },
              on: { close: _vm.onClose, onSubmit: _vm.submit },
            },
            [
              _c(
                "div",
                { staticClass: "drawer-content" },
                [
                  _c("a-checkbox-group", {
                    attrs: { options: _vm.plainOptions },
                    on: { change: _vm.onCheck },
                    model: {
                      value: _vm.checkedList,
                      callback: function ($$v) {
                        _vm.checkedList = $$v
                      },
                      expression: "checkedList",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "Modal",
            {
              attrs: { visible: _vm.changeVisible, title: "选择参考模板" },
              on: {
                cancel: function ($event) {
                  _vm.changeVisible = false
                },
                ok: function ($event) {
                  return _vm.getSchemeDataListBySchemeId(_vm.tempId)
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "选定模板时，模板数据会覆盖当前参保方案，方案原数据将消失，确认选择当前模板吗？"
                ),
              ]),
            ]
          ),
          _c(
            "Modal",
            {
              attrs: { visible: _vm.tenantBillStatusShow, title: "提示" },
              on: {
                cancel: function ($event) {
                  _vm.tenantBillStatusShow = false
                },
                ok: _vm.validForm,
              },
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.tenantBillStatus === 1
                      ? "提示：本月账单已经生成，如要变更基数，需要使用【账单】功能重新生成本月账单，否则基数变更只能下个月生效，是否确定要保存？"
                      : "注意：本月账单已经生成并锁定，基数变更只能下个月生效，是否确定要保存？"
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }